import { create } from "zustand";

export type PaymentLabsApp =
  | "gateway"
  | "payee"
  | "payor"
  | "merchant"
  | "checkout"
  | "backoffice";

type EnvStoreData = {
  app: PaymentLabsApp;
  apiBaseurl: string;
};

type EnvStore = EnvStoreData & {
  update: (data: Partial<EnvStoreData>) => void;
  reset: () => void;
};

const initialStoreData = {
  app: undefined,
  apiBaseurl: undefined,
} as unknown as EnvStoreData;

export const useEnvStore = create<EnvStore>((set, get) => ({
  ...initialStoreData,
  update: (data) => {
    set(() => ({ ...data }));
  },
  reset: () => set({ ...get(), ...initialStoreData }),
}));
