import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  createCheckout: () => "/create-checkout",
  getCheckout: (id: string) => `/checkout/attempt/${id}`,
  settings: () => "/settings",
};

export class MerchantRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.createCheckout(),
        RouteMap.getCheckout("[id]"),
        RouteMap.settings(),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToCreateCheckout() {
    this.goTo(RouteMap.createCheckout());
  }

  goToCheckout(id: string) {
    this.goTo(RouteMap.getCheckout(id));
  }

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteCreateCheckout() {
    return this.pathname === RouteMap.createCheckout();
  }

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }
}
