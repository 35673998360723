/*
 * In (at least) Chrome, the "Allow all cookies" in "Privacy and security -
 * Cookies and other site data" must be checked in order for an iframe of a
 * different origin than the parent to access sessionStorage (when in
 * Incognito).
 *
 * https://stackoverflow.com/a/26671889
 * https://github.com/auth0/auth0-spa-js/issues/593
 *
 * There are a couple possible workarounds:
 * - Keep the iframe on the same origin as the parent. This isn't really
 *   feasible for various reasons.
 * - Keep the data in memory.
 *
 * We're doing the second option, with sessionStorage as a fallback. Doing it
 * this way will work for the widget iframe (in memory), and also for non-iframe
 * contexts - benefit being, non-iframe contexts can benefit from token
 * persistence while the session is active.
 */
import {
  AccountType,
  ActiveAccount,
  useAuthStore,
} from "#app-services/stores/useAuthStore";
import {
  getAuthTokenFromSessionStorage,
  setAuthTokenInSessionStorage,
} from "#app-services/utils/sessionStorage";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "./localStorage";
import { logFatal } from "./logger";

export const ACTIVE_PAYOR_ID_KEY = "pl-active-account";
const REMEMBER_DEVICE_TOKEN_KEY = "pl-2fa-device";

type TokenObject = {
  exp: number;
  role: "2fa" | "user" | "guest" | "backoffice" | "admin";
  payees: string;
  payors: string;
  merchant?: boolean;
};

export function getAuthToken(inMemoryOnly?: boolean) {
  const tokenFromStore = useAuthStore.getState().token;
  if (typeof tokenFromStore === "string" || inMemoryOnly) {
    return tokenFromStore;
  }

  return getAuthTokenFromSessionStorage();
}

export function setAuthToken(token: string) {
  useAuthStore.getState().update({ token });
  setAuthTokenInSessionStorage(token);
}

export function getTokenState(): "invalid" | "expired" | "valid" {
  const token = getAuthToken();
  if (!token) {
    // if token not exists, we handle it as need a new login
    return "expired";
  }

  const decodedToken = decodeJwt(token);

  if (!decodedToken) {
    return "invalid";
  }

  const ValidTokenRoles: Array<typeof decodedToken.role> = [
    "2fa",
    "user",
    "guest",
    "backoffice",
    "admin",
  ];

  if (decodedToken.exp && new Date() > new Date(decodedToken.exp * 1000)) {
    return "expired";
  } else if (!ValidTokenRoles.includes(decodedToken.role)) {
    return "invalid";
  }

  return "valid";
}

export function getTokenObject() {
  const token = getAuthToken();

  if (!token) {
    return null;
  }

  return decodeJwt(token);
}

export function get2faRememberDeviceToken(): string | undefined {
  return getLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY) || undefined;
}

export function set2faRememberDeviceToken(token: string): void {
  setLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY, token);
}

export function clear2faRememberDeviceToken(): void {
  removeLocalStorageItem(REMEMBER_DEVICE_TOKEN_KEY);
}

export function reset() {
  useAuthStore.getState().reset();
  expireToken();
}

export function updateActiveAccount(payorId: string, type: AccountType) {
  const account = {
    payorId,
    type,
  };

  setLocalStorageItem(ACTIVE_PAYOR_ID_KEY, JSON.stringify(account));
  useAuthStore.getState().update({
    activeAccount: account,
  });

  return account;
}

export function getActiveAccount(): ActiveAccount | undefined {
  const activeAccount =
    useAuthStore.getState().activeAccount ||
    JSON.parse(getLocalStorageItem(ACTIVE_PAYOR_ID_KEY) || "{}");

  if (activeAccount && Object.keys(activeAccount).length > 0) {
    return activeAccount;
  }
}

/**
 *
 * Since we have the active account info in two locations (session storage and zustand store),
 *
 * This function is used to sync the active account from both locations, to make sure we are
 * avoiding any inconsistency between the two locations.
 */
export function syncActiveAccount() {
  const activeAccount = getActiveAccount();

  if (activeAccount) {
    return updateActiveAccount(activeAccount.payorId, activeAccount.type);
  }
}

/**
 *
 * The JWT token can contains the following claims:
 *
 * "payors": "C-01GQ8FAHB20JPZYZMZZYPZ0C3J:user",
 * "merchants": "C-01GQ8FAHB20JPZYZMZZYPZ0C3J:user",
 * "payees": "C-01GQ8FAHB20JPZYZMZZYPZ0C3J:U-01H1CECHM3FC8NYAZ55J7D4PTS;C-01HDPM78E59YS0S6BGHBCGK84B:U-01H1CECHM3FC8NYAZ55J7D4PTS;"
 *
 * This is a scenario of a full user, that contains contains the three account types (Payee, Payor and Merchant)
 * and for that we have the select account screen in the login process.
 *
 *
 * For Widget Iframe, where we only have one single payor, and does not have the select account screen, we set the only payor existent
 * in their jwt token, and we set it as the active account
 *
 * e.g: "payees": "C-01GQ8FAHB20JPZYZMZZYPZ0C3J:U-01H1CECHM3FC8NYAZ55J7D4PTS"
 *
 */
export function setActiveAccountFromToken() {
  const token = getTokenObject();

  const idsFromPayees = token?.payees
    ?.split(";")
    .map((pair: string) => pair.split(":")[0]);

  if (idsFromPayees && idsFromPayees.length === 1) {
    return updateActiveAccount(idsFromPayees[0], "payee");
  }
}

function expireToken(): void {
  setAuthTokenInSessionStorage("");
  useAuthStore.getState().update({ token: undefined });
}

function decodeJwt(encoded: string): TokenObject | null {
  try {
    const jwt = encoded.split(".");
    return JSON.parse(atob(jwt[1]));
  } catch (e) {
    logFatal({ message: "Unexpected JWT parse error", exception: e as Error });
    return null;
  }
}
