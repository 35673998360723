import { create } from "zustand";
import { PaymentLabsApp } from "#app-services/stores/useEnvStore";

export type AccountType = Extract<
  PaymentLabsApp,
  "payor" | "payee" | "merchant" | "checkout"
>;

export type ActiveAccount = {
  payorId: string;
  type: AccountType;
};

type AuthStoreData = {
  token?: string;
  activeAccount?: {
    payorId: string;
    type: AccountType;
  };
};

type AuthStore = AuthStoreData & {
  update: (data: Partial<AuthStoreData>) => void;
  reset: () => void;
};

const initialStoreData = {
  token: undefined,
  activeAccount: undefined,
};

export const useAuthStore = create<AuthStore>((set, get) => ({
  ...initialStoreData,
  update: (data) => {
    set(() => ({ ...data }));
  },
  reset: () => set({ ...get(), ...initialStoreData }),
}));
