import { TaxJurisdiction } from "@paymentlabs/utils-model-external";
import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  onboard: () => "/onboard",
  addPaymentMethod: () => "/add-payment-method",
  addTaxForm: (jurisdiction: TaxJurisdiction) =>
    `/add-tax-form/${jurisdiction}`,
  editPersonalInfo: () => "/edit-personal-info",
  verifyIdentity: () => "/verify-identity",
  payment: (id: string) => `/payment/${id}`,
  allPayments: () => "/payments/all",
  settings: () => "/settings",
};

export class PayeeRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  goTo(url: string) {
    // If there's a basePath set and the target route is an auth route we always
    // want to use external nav. A basePath being set means we're on the portal,
    // which uses the gateway's auth pages.
    super.goTo(
      url,
      this.isUnrestrictedAccountRoute(url) && this.basePath
        ? "external"
        : undefined
    );
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return (
      super.isRestrictedRoute(pathname) ||
      [
        RouteMap.onboard(),
        RouteMap.addPaymentMethod(),
        RouteMap.addTaxForm("[jurisdiction]" as TaxJurisdiction),
        RouteMap.editPersonalInfo(),
        RouteMap.verifyIdentity(),
        RouteMap.payment("[id]"),
        RouteMap.allPayments(),
        RouteMap.settings(),
      ].includes(pathname || this.pathname)
    );
  }

  /*
   * Restricted Routes - goTo
   */

  goToSettings() {
    this.goTo(RouteMap.settings());
  }

  /*
   * Restricted Routes - Reflection
   */

  isCurrentRouteSettings() {
    return this.pathname === RouteMap.settings();
  }
}
