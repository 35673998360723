import { BaseRouterModel } from "./BaseRouterModel";

const RouteMap = {
  checkoutAttempt: (checkoutId?: string) => `/checkout/attempt/${checkoutId}`,
};

export class CheckoutRouterModel extends BaseRouterModel {
  constructor(
    basePath: string,
    pathname: string,
    push: (pathname: string) => void
  ) {
    super(basePath, pathname, push);
  }

  getRouteMap() {
    return {
      ...super.getRouteMap(),
      ...RouteMap,
    };
  }

  /*
   * Route Group Reflection
   */

  isRestrictedRoute(pathname?: string): boolean {
    return super.isRestrictedRoute(pathname);
  }

  goToSignUp(encryptedConfig?: string): void {
    this.goTo(`${super.getRouteMap().signUp()}?config=${encryptedConfig}`);
  }

  goToForgotPassword(encryptedConfig?: string): void {
    this.goTo(
      `${super.getRouteMap().forgotPassword()}?config=${encryptedConfig}`
    );
  }

  goToLogin(checkoutId?: string): void {
    this.goTo(
      `${super.getRouteMap().login()}?_redirectUrl=${this.getRouteMap().checkoutAttempt(checkoutId)}`
    );
  }

  goToResetPassword(encryptedConfig?: string): void {
    this.goTo(
      `${super.getRouteMap().resetPassword()}?config=${encryptedConfig}`
    );
  }
}
